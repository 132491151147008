export async function diffCollection (initialItems, latestItems, addFn, removeFn, updateFn = null, key = null, attributesToCompare = null) {
  let addedItems = null
  let removedItems = null
  let updatedItems = null

  if (key !== null) {
    // Object comparison
    addedItems = latestItems.filter(function (item) {
      if (typeof item[key] === 'undefined' && (!attributesToCompare?.length || attributesToCompare.filter(x => Object.keys(item).includes(x)).length)) return true // there is no key, this is an addition too if there is a value
      return !initialItems.filter(i => i[key] === item[key]).length
    })
    removedItems = initialItems.filter(function (item) {
      return !latestItems.filter(i => i[key] === item[key]).length
    })
    if (typeof updateFn === 'function' && attributesToCompare !== null) {
      const possibleUpdatedItems = latestItems.filter(function (item) {
        if (typeof item[key] === 'undefined') return false // there is no key, this is an addition, not an update
        return initialItems.filter(i => i[key] === item[key]).length
      })

      updatedItems = possibleUpdatedItems.filter(function (possibleUpdatedItem) {
        const initialValues = Object.entries(initialItems.filter(i => i[key] === possibleUpdatedItem[key])[0]).map(function ([key, value]) {
          if (attributesToCompare.includes(key)) {
            return value
          }
        }).filter(Boolean)
        const latestValues = Object.entries(possibleUpdatedItem).map(function ([key, value]) {
          if (attributesToCompare.includes(key)) {
            return value
          }
        }).filter(Boolean)

        return JSON.stringify(initialValues) !== JSON.stringify(latestValues)
      })
    }
  } else {
    // Array of string/int comparison
    addedItems = latestItems.filter(item => !initialItems.includes(item))
    removedItems = initialItems.filter(item => !latestItems.includes(item))
  }

  for (let i = 0; i < addedItems.length; i++) {
    await addFn(addedItems[i])
  }

  for (let i = 0; i < removedItems.length; i++) {
    await removeFn(removedItems[i])
  }

  if (updatedItems !== null) {
    for (let i = 0; i < updatedItems.length; i++) {
      await updateFn(updatedItems[i])
    }
  }
}

export function csv (fileName, data) {
  const content = data.map(line => line.join(';')).join('\r\n')
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(new Blob([content], { type: 'text/csv' }))
  a.setAttribute('download', fileName)
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export async function download (res) {
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(res.data)
  a.download = res.headers['content-disposition'].match(/filename="?([^=";]+)"?/)[1]
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const downloadTagDocumentMixin = {
  methods: {
    async downloadTagDocument (tags) {
      download(await this.$api.assets('/tags/documentation', {
        client_name: this.$root.client.name,
        tags: tags.reduce((tags, tag) => ({
          ...tags,
          [tag.type]: [...tags[tag.type] || [], { name: tag.name, code: tag.contents }]
        }), { CONVERSION: [] })
      }, { responseType: 'blob' }))
    }
  }
}

export function cloneDeep (object) {
  if (typeof object !== 'object') return object
  return JSON.parse(JSON.stringify(object))
}

export function extractPlaceholders (data, prefix = '') {
  let result = {}

  for (const key in data) {
    if (typeof data[key] === 'object') {
      result = { ...result, ...extractPlaceholders(data[key], `${prefix}${key}.`) }
    } else {
      result[`${prefix}${key}`] = data[key]
    }
  }

  return result
}

Object.defineProperty(Array.prototype, 'minForObjAttr', {
  value: function (objectAttribute) {
    return (this.length && this.reduce(function (prev, curr) {
      return (prev[objectAttribute] < curr[objectAttribute]) ? prev : curr
    })) || null
  },
  writable: false,
  configurable: false,
  enumerable: false
});

Object.defineProperty(Array.prototype, 'maxForObjAttr', {
  value: function (objectAttribute) {
    return (this.length && this.reduce(function (prev, curr) {
      return prev[objectAttribute] > curr[objectAttribute] ? prev : curr
    })) || null
  },
  writable: false,
  configurable: false,
  enumerable: false
});

String.prototype.stripAllNonASCII = function () {
  return this.valueOf().replaceAll(/[^a-z0-9_-]+/gi, '')
}

export function isAllowed (value) {
  return ['yes', '1', 'on', 'active', 'enabled', 'true'].includes(String(value).toLowerCase().trim())
}
export function isDisallowed (value) {
  return ['no', '0', 'off', 'inactive', 'disabled', 'false'].includes(String(value).toLowerCase().trim())
}

export function objectSumGrouped (arr, attributeToGroup, attributeToSum = null) {
  return arr.reduce((accumulator, currentValue) => {
    if (!currentValue.hasOwnProperty(attributeToGroup) || (attributeToSum && !currentValue.hasOwnProperty(attributeToSum))) return accumulator
    if (!accumulator.hasOwnProperty(currentValue[attributeToGroup])) {
      accumulator[currentValue[attributeToGroup]] = 0
    }
    if (attributeToSum && currentValue.hasOwnProperty(attributeToSum)) {
      accumulator[currentValue[attributeToGroup]] += Number.parseFloat(currentValue[attributeToSum])
    } else {
      accumulator[currentValue[attributeToGroup]] += 1
    }

    return accumulator
  }, {})
}

export function objectSum (arr, attributeToSum, sumFn = null) {
  return arr.reduce((accumulator, currentValue) => {
    if (!currentValue.hasOwnProperty(attributeToSum)) return accumulator
    if (sumFn && typeof sumFn === 'function') {
      return accumulator + sumFn(currentValue[attributeToSum])
    }
    return accumulator + Number.parseFloat(currentValue[attributeToSum])
  }, 0)
}

export async function imageUrlToBase64 (url){
  const response = await fetch(url)
  const blob = await response.blob()
  return new Promise((onSuccess, onError) => {
    try {
      const reader = new FileReader()
      reader.onload = function(){ onSuccess(this.result) }
      reader.readAsDataURL(blob)
    } catch(e) {
      onError(e)
    }
  })
}
