<template>
  <!-- <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x"> -->
  <b-navbar :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">

    <!-- Brand -->
    <!-- <b-navbar-brand to="/">
      <span class="app-brand-logo"><img :src="logo" alt="Exatom"></span>
    </b-navbar-brand> -->

    <!-- Sidenav toggle -->
    <b-navbar-nav class="d-block d-lg-none mr-4" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <!-- Navbar toggle -->
    <!-- <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle> -->

    <b-collapse is-nav id="app-layout-navbar">
      <b-navbar-nav class="align-items-lg-center">
        <div v-if="!selector && $root.me.organization" class="mr-2">
          <span class="text-primary font-weight-bolder letter-spacing-1px text-uppercase" style="font-size: 2rem; letter-spacing: 3px;">{{ $root.me.organization.name }}</span>
        </div>
        <b-nav-item-dropdown ref="clientSelector" menu-class="client-selector" v-if="selector" @shown="clientFilter = ''; $refs.clientFilterInput.focus()" id="clientSelectorMenu">
          <template #button-content>
            <span style="color: #888888;"><i class="material-icons icon-project">folder_open</i>&nbsp; {{ (client || {}).name }}</span>
          </template>
          <b-dropdown-form @submit.prevent>
            <label class="nav-item navbar-text navbar-search-box p-0 active">
            <i class="material-icons icon-search">search</i>
              <span class="navbar-search-input">
                <input type="search" ref="clientFilterInput" v-model="clientFilter" @keydown.down="clientFilterDown" @keyup.enter="handleSelect" class="form-control navbar-text" placeholder="Search..." style="width:200px">
              </span>
            </label>
          </b-dropdown-form>
          <template v-for="row in selector">
            <!-- Clients w/o an org -->
            <b-dropdown-item-button v-if="row.type === 'client'" :key="row.item.client_hash" @click="switchClient(row.item)" :active="$root.client && row.item.client_hash === $root.client.client_hash">{{ row.item.name }}</b-dropdown-item-button>
            <!-- Clients w/ an org -->
            <template v-else>
              <b-dropdown-group :key="row.item.organization_hash" :header="row.item.name" v-if="row.item.filteredClients.filter(c => typeof c.hide === 'undefined' || clientFilter.length).length">
                <b-dropdown-item-button
                  v-for="oc in row.item.filteredClients" :key="oc.client_hash"
                  @click="switchClient(oc)"
                  :active="$root.client && oc.client_hash === $root.client.client_hash"
                  :style="oc && oc.hide && clientFilter.length === 0 ? 'display: none;' : ''">
                  {{ oc.name }}<span class="badge badge-danger badge-dot ml-1" v-b-tooltip.right :title="oc.status" v-if="oc.status !== 'ACTIVE'"></span>
                </b-dropdown-item-button>
              </b-dropdown-group>
            </template>
          </template>
          <b-dropdown-text v-if="!selector.length">
            No websites found.
          </b-dropdown-text>
        </b-nav-item-dropdown>
        <span role="status" class="spinner-border text-primary d-none"><span class="sr-only">Loading</span></span>
      </b-navbar-nav>
      <b-navbar-nav class="align-items-lg-center ml-auto">
        <template>
          <b-nav-item-dropdown no-caret :right="!isRtlMode" class="navbar-notifications mr-lg-2" ref="dropdown">
            <template #button-content>
              <i class="material-icons icon-notifications" :class="notifications.length ? 'text-primary' : ''">notifications</i>
              <span class="badge badge-danger badge-dot indicator" v-if="notifications.length"></span>
            </template>

           <div class="title-dropdown-notifications p-3">
              <i class="material-icons icon-notifications-dropdown">notifications</i> {{ notifications.length ? notifications.length : 'No' }} new Notifications
            </div>
            <b-list-group flush>
              <b-list-group-item :to="{name: 'performance-alerts-notifications'}" class="media d-flex align-items-center border-0"
                                 @click="$refs.dropdown.hide()"
                                 v-for="notification in notifications.slice(0, 5)" :key="notification.id">
                <div class="media-body line-height-condenced panel-pos">
                  <div class="text-body">{{ notification.check_subscription.check.name }}</div>
                  <span class="text-light small mt-1" v-b-tooltip.left :title="notification.created_at | datetime">{{ notification.created_at | dateRelative }}</span>
                </div>
              </b-list-group-item>
            </b-list-group>

            <router-link :to="{name: 'performance-alerts-notifications'}" class="d-block text-center blue small p-3 my-1"
               v-if="notifications.length > 5">Show all notifications</router-link>
          </b-nav-item-dropdown>
        </template>

        <b-nav-item href="https://support.exatom.io/" target="_blank" class="d-none d-sm-inline-block">
          <i class="material-icons" style="padding-top: 5px;" title="Knowledge base">menu_book</i>
        </b-nav-item>

        <b-nav-item-dropdown :right="!isRtlMode" @hide="showDevItemsInMenu = false">
          <template slot="button-content">
            <span class="d-inline-flex align-items-center align-middle" @click.alt="showDevItemsInMenu = true">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0 d-none d-sm-block">{{ $root.me.name }}</span>
              <gravatar :email="$root.me.email" :initials="$root.me.name.split(/[^a-z0-9]/i).map((n) => n[0]).join('')" :size="30" class="badge-exatom-grey"/>
            </span>
          </template>

          <template v-if="showDevItemsInMenu">
            <b-dd-item v-clipboard:copy="this.$root.client.client_code">
              <b>Client code:</b> {{ this.$root.client.client_code }}
            </b-dd-item>
            <b-dd-item v-clipboard:copy="this.$root.client.client_hash">
              <b>Client hash:</b> {{ this.$root.client.client_hash }}
            </b-dd-item>
            <b-dd-item v-for="(value, key) in $root.debugData" v-clipboard:copy="value" :key="key">
              <b>{{ key }}:</b> {{ value }}
            </b-dd-item>
            <b-dd-divider />
          </template>

          <b-dd-item :to="{ name: 'profile' }">
            <i class="material-icons icon-profile">person</i> &nbsp; My profile
          </b-dd-item>
          <b-dd-divider/>
          <template v-if="this.$root.hasRole('admin.internal', true)">
            <b-dd-item @click="toggleAdminPrivileges">
              <span :class="{ 'text-success': !this.$root.hasRole('admin.internal')}"><i class="material-icons icon-profile">supervised_user_circle</i> &nbsp; Demo</span>
            </b-dd-item>
            <b-dd-divider/>
          </template>
          <template v-if="$root.hasRole('admin.*') || $root.hasRole('finance')">
            <b-dd-item :to="{ name: 'account' }">
              <i class="material-icons icon-profile">credit_score</i> &nbsp; Manage your account
            </b-dd-item>
            <b-dd-item :to="{ name: 'account', query: { item: 'billingHistory' } }" v-if="$root.me.payment_provider.customer_id">
              <i class="material-icons icon-profile">description</i> &nbsp; Billing history
            </b-dd-item>
            <b-dd-divider/>
          </template>
          <b-dd-item href="https://support.exatom.io/" target="_blank">
            <i class="material-icons icon-profile" title="Knowledge base">contact_support</i> &nbsp; Knowledge base
          </b-dd-item>
          <b-dd-item @click="$root.createTicket">
            <i class="material-icons icon-profile" title="Contact support">email</i> &nbsp; Contact support
          </b-dd-item>
          <b-dd-divider/>
          <b-dd-item @click="logout">
            <i class="material-icons icon-profile">logout</i> &nbsp; Log Out
          </b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import logo from '@/assets/logo_xs.png'
import gravatar from '@/components/Gravatar'
import { cloneDeep } from '@/utils'

const sessionStorage = window.sessionStorage || {}
const localStorage = window.localStorage || {}

export default {
  name: 'header-navigation',
  components: { gravatar },

  data () {
    return {
      logo,
      notifications: [],
      client: null,
      clientFilter: '',
      showDevItemsInMenu: false
    }
  },

  mounted () {
    if (!this.client) {
      this.selectCorrectClient()
    }

    this.$root.$on('shift-pressed-twice', (msg) => {
      this.$refs.clientSelector.show()
    })

    this.$root.$on('notification-updated', (msg) => {
      this.loadNotifications()
    })

    window.addEventListener('keydown', (event) => {
      if (event.key === 'Alt') {
        this.showDevItemsInMenu = true
      }
    })
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Alt') {
        this.showDevItemsInMenu = false
      }
    })
  },

  computed: {
    selector () {
      if (this.$route.path.indexOf('/administration/') !== -1) {
        // On administration pages we do not need to show (causes confusion)
        return null
      }

      if (!this.$root.me.grants.combined.length) {
        return null
      }

      const filteredClients = this.$root.me.grants.combined
        .filter(client => !this.clientFilter || `${client.name} ${client.client_code}`.toLowerCase().includes(this.clientFilter.toLowerCase()))
      const filteredClientIds = filteredClients
        .map(client => client.client_hash)

      // All clients needs to be part of an organization
      // --
      // const orgClientIds = this.$root.me.grants.organizations
      //   .reduce((prev, org) => prev.concat(org.clients), [])
      //   .map(client => client.client_hash)

      // List of orgs and all clients the user has access to
      const orgClients = []
      this.$root.me.grants.combined
        .forEach(client => {
          if (!client.organizations.length) {
            console.error('Client that is not assigned to an organization, skipping', client)
            return
          }

          // Create base keys
          if (!orgClients.filter(oc => oc.organization_hash === client.organizations[0].organization_hash).length) {
            orgClients.push({
              ...cloneDeep(client.organizations[0]),
              clients: []
            })
          }

          // Add client to org
          orgClients.filter(oc => oc.organization_hash === client.organizations[0].organization_hash)[0].clients.push(client)
        })

      return orgClients
        .map(org => ({
          ...org,
          filteredClients: (
            org.name.toLowerCase().includes(this.clientFilter.toLowerCase()) ? org.clients : org.clients.filter(client => filteredClientIds.includes(client.client_hash))
          )
            .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
            .map(c => {
              // Filter out inactive ones for ADMINS when not searching
              if (this.$root.hasRole('admin.internal') && c.status !== 'ACTIVE') {
                c.hide = true
              }
              return c
            })
        }))
        .filter(org => org.filteredClients.length)
        .filter(org => org.status === 'ACTIVE')
        .map(item => ({ item, type: 'org' }))
        // All clients needs to be part of an organization
        // --
        // .concat(
        //   filteredClients.filter(client => !orgClientIds.includes(client.client_hash))
        //     .filter(client => {
        //       return (client.status === 'ACTIVE')
        //     })
        //     .map(item => ({ item, type: 'client' }))
        // )
        .sort((a, b) => a.item.name.toLowerCase() > b.item.name.toLowerCase() ? 1 : -1)
    }
  },

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    },

    clientFilterDown (e) {
      document.querySelector('.client-selector button').focus()
    },

    switchClient (client) {
      this.client = client
      if (this.$route.meta.redirectAfterSwitch) {
        this.$router.push({ name: this.$route.meta.redirectAfterSwitch })
      }
    },

    async logout () {
      await this.$api.logout()
    },

    async loadNotifications () {
      this.notifications = await this.$api.get('/alerts/notifications/unread', this.$root.client.client_hash)
    },

    handleSelect () {
      // If there is just one client, no need to select it first with the arrow key down
      if (this.selector.length === 1 && this.selector[0].item.filteredClients.length === 1) {
        this.switchClient(this.selector[0].item.filteredClients[0])
        this.$refs.clientSelector.hide()
      }
    },

    toggleAdminPrivileges () {
      // Non-admins cannot exec this
      if (!this.$root.hasRole('admin.internal', true)) return

      if (this.$root.hasRole('admin.internal')) {
        this.$root.me.roles = this.$root.me.roles.filter(r => r.name !== 'admin.internal')
      } else {
        this.$root.resetRoles()
      }

      this.deleteLocalStorageConversionPathMetricSampleHover()
    },
    deleteLocalStorageConversionPathMetricSampleHover () {
      delete localStorage.conversionPathMetricSampleHover
      delete localStorage.conversionPathMetricSampleHoverDatetime
    },
    async selectCorrectClient () {
      this.client = this.$root.me.grants.combined.filter(grant => grant.client_hash === this.$route.query.client_hash)[0] ||
        this.$root.me.grants.combined.filter(grant => grant.client_hash === sessionStorage.client_hash)[0] ||
        this.$root.me.grants.combined.filter(grant => grant.client_hash === localStorage.client_hash)[0] ||
        this.$root.me.grants.combined[0]
    }
  },

  watch: {
    async client () {
      if (!this.$root.me.grants.combined.length) {
        return null
      }

      this.$root.client = this.client
      sessionStorage.client_hash = localStorage.client_hash = this.client.client_hash

      // Add subscription usage
      this.$api.get(`/clients/${this.client.client_hash}/subscription-usage`).then(response => {
        this.$set(this.$root.client, 'subscription_usage', response.data)
      })

      // Reset permissions if needed
      this.$root.resetRoles()

      // Check if subscription is expired
      this.$root.isSubscriptionExpired()

      await this.loadNotifications()
    },
    '$root.me': {
      async handler () {
        await this.selectCorrectClient()
      }
    },
    '$route.query.client_hash': {
      async handler () {
        await this.selectCorrectClient()
      }
    }
  }
}
</script>

<style>
  .axios-loading .spinner-border {
    display: inline-block !important;
  }
</style>

<style scoped>
  @media (max-width: 576px) {
    #clientSelectorMenu a span {
      display: inline-block !important;
      width: 10rem !important;
      text-overflow: ellipsis !important;
      overflow-x: hidden;
    }

    #clientSelectorMenu:deep(.dropdown-toggle::after) {
      margin-top: -1.2rem;
    }

    :deep(.client-selector) {
      left: -1rem !important;
    }
  }

  :deep(.client-selector) {
    padding-top: 0;
  }

  .client-selector .dropdown-item:hover {
    background: none;
  }

  .client-selector .dropdown-header {
    font-size: 1.1rem;
    color: black;
    border-bottom: 1px solid #ccc;
    margin: 0.25rem 1.25rem 0;
    padding-left: 0;
    padding-right: 0;
    letter-spacing: 0.5px;
  }

 .client-selector li:deep(button:focus::after),
  .client-selector li:deep(button:hover::after) {
      content: '\f1df';
  float: right;
  font-family: 'Material Icons';
  }
</style>

<style>
.bg-exatom-blue {
  background-color: var(--exatom-blue);
}

.navbar-notifications .dropdown-toggle,
.navbar-notifications.b-nav-dropdown .nav-link {
  white-space: nowrap;
}

.navbar-notifications .dropdown-menu {
  overflow: hidden;
  padding: 0;
}

@media (min-width: 992px) {
  .navbar-notifications .dropdown-menu {
    margin-top: .5rem;
    width: 22rem;
  }
}
</style>
