<template>
  <div>
    <div class="my-5 text-center">
      <h4 class="font-weight-bolder letter-spacing-1px mb-3">
        Sign in to your account
      </h4>
      <div class="small">
        Don't have an account? <router-link :to="{ name: 'signup' }">Start your 15-day free trial.</router-link>
      </div>
    </div>
    <form id="loginForm" class="my-5" @submit.prevent="submit">
      <div class="col-form-label text-left text-dark">Work email address</div>
      <b-input id="email" class="input-login" type="email" autocomplete="username" required v-model="credentials.email" autofocus tabindex="1" placeholder="Your work email address" />
      <div class="col-form-label text-left text-dark mt-2">Password</div>
      <b-input id="password" class="input-login" type="password" autocomplete="current-password" required v-model="credentials.password" tabindex="2" placeholder="Your password" />
      <router-link :to="{ path: '/password-reset/' + credentials.email }" class="text-primary small mb-3 mt-1" style="margin-left: 2px;">
        Forgot password?
      </router-link>
      <div class="d-flex justify-content-end align-items-center">
        <ladda-btn :loading="loading" data-style="expand-left" type="submit" class="btn btn-login" tabindex="3" style="min-width: 97px;">Sign In</ladda-btn>
      </div>
    </form>

    <b-alert v-model="error.visible" dismissible variant="danger" class="mt-5">{{ error.text }}</b-alert>

  </div>
</template>

<script>
import LaddaBtn from '@/vendor/libs/ladda/Ladda'
import router from '@/router'

export default {
  name: 'login',
  metaInfo: {
    title: 'Exatom - Login to your account'
  },
  components: { LaddaBtn },
  data () {
    return {
      credentials: {
        email: (typeof this.$route.params.email !== 'undefined' ? this.$route.params.email : ''),
        password: ''
      },
      error: {
        text: (typeof this.$route.query.error !== 'undefined' ? this.$route.query.error : ''),
        visible: typeof this.$route.query.error !== 'undefined'
      },
      loading: false
    }
  },
  async mounted () {
    if (typeof this.$route.params.email !== 'undefined') {
      // Change URL to not hold PII data
      history.replaceState({}, '', document.location.origin + '/login')
    }

    if (typeof this.$route.query.token !== 'undefined') {
      // Change URL to not hold PII data
      history.replaceState({}, '', document.location.origin + '/login')

      if (this.$route.query.token.length > 512) {
        localStorage.apiToken = this.$route.query.token
        await this.$root.$api.refresh()
      }
    }

    // Is the user already logged in?
    if (!this.$api.isLoginTokenExpired()) {
      await router.push({ path: '/' })
    } else {
      // Make sure if there is a previous selected client it's cleared
      this.$root.client = null
    }
  },
  methods: {
    async submit () {
      this.error.visible = false
      this.loading = true

      try {
        await this.$api.login(this.credentials)

        // When logging-in again we reset the previously held insights custom timeframe
        // -> sessionStorage = per tab
        // -> localStorage = browser (across tabs)
        window.sessionStorage.removeItem('insights_custom_from')
        window.sessionStorage.removeItem('insights_custom_to')

        this.loading = false

        if (this.$route.query.redirect) {
          window.location.href = this.$route.query.redirect
        } else {
          this.$router.push({ path: '/' })
        }
      } catch (error) {
        this.loading = false
        if (error.response && error.response.status === 401) {
          this.error = {
            text: 'Invalid email and password combination.',
            visible: true
          }
        } else {
          this.error = {
            text: 'A technical error occurred, please try again in a few minutes.',
            visible: true
          }
        }
      }
    }
  }
}
</script>
