<template>
  <b-avatar v-if="base64" :src="base64" :rounded="rounded" :size="size" :variant="null" :text="initials" class="text-primary font-weight-bolder letter-spacing-1px"></b-avatar>
  <b-avatar v-else-if="email" :src="src" :rounded="rounded" :size="size" :variant="null" :text="initials" class="text-primary font-weight-bolder letter-spacing-1px"></b-avatar>
  <b-avatar v-else :rounded="rounded" :size="size" :variant="null" :text="initials" class="text-primary font-weight-bolder letter-spacing-1px"></b-avatar>
</template>

<script>
import md5 from 'blueimp-md5'

export default {
  name: 'gravatar',
  props: ['email', 'rounded', 'size', 'initials', 'base64'],
  computed: {
    src () {
      return `https://www.gravatar.com/avatar/${md5(this.email.toLowerCase())}?d=404`
    }
  }
}
</script>
