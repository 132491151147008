<template>
  <nav class="layout-footer footer" :class="getLayoutFooterBg()">
    <div class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
      <div class="pt-3">
        <span class="pr-2"><span class="footer-text font-weight-bolder letter-spacing-1px">Exatom</span> © {{ year }}</span>
        <br class="d-block d-sm-none"/>
        <a href="https://exatom.io/about/" class="footer-link d-print-none text-light px-2" target="_blank">About Us</a>
        <a href="" @click.prevent="$root.createTicket()" class="footer-link d-print-none text-light px-2" target="_blank">Contact support</a>
        <a href="https://support.exatom.io/" class="footer-link d-print-none text-light px-2" target="_blank">Knowledge base</a>
        <a href="https://exatom.io/terms-and-conditions/" class="footer-link d-print-none text-light px-2" target="_blank">Terms &amp; Conditions</a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'footer-navigation',

  computed: {
    year () {
      const year = (new Date()).getFullYear()
      if (year === 2021) {
        return year
      } else {
        return '2021 - ' + year
      }
    }
  },

  methods: {
    getLayoutFooterBg () {
      return `bg-${this.layoutFooterBg}`
    }
  }
}
</script>
