<template>
  <div class="authentication-wrapper authentication-3">
    <div class="authentication-inner">

      <!-- Side container -->
      <!-- Do not display the container on extra small, small and medium screens -->
      <div class="d-none d-xl-flex col-xl-7 align-items-start ui-bg-cover ui-bg-overlay-container p-5 auth-background">
        <!-- Text -->
        <div class="auth-hero text-dark">
          <template v-if="$route.path.indexOf('/subscription/welcome') !== -1">
            <h1 class="display-2 font-weight-bolder letter-spacing-1px mb-4 login-tagline">We're pleased to have you on board!</h1>
            <div class="body-login">
              Analyzing your forms was never so easy, we'll get you started in a matter of minutes.
            </div>
          </template>
          <template v-if="$route.path.indexOf('/signup') !== -1">
            <h1 class="display-2 font-weight-bolder letter-spacing-1px mb-4 login-tagline">Start your free trial today</h1>
            <div class="body-login">
              <p><i class="material-icons text-primary" style="vertical-align: top">check</i> No credit card required</p>
              <p><i class="material-icons text-primary" style="vertical-align: top">check</i> 15-days free of charge</p>
              <p><i class="material-icons text-primary" style="vertical-align: top">check</i> All features and functionalities</p>
              <p><i class="material-icons text-primary" style="vertical-align: top">check</i> No-code setup</p>
              <p><i class="material-icons text-primary" style="vertical-align: top">check</i> 100% GDPR compliant</p>
            </div>
          </template>
          <template v-else>
            <h1 class="display-2 font-weight-bolder letter-spacing-1px mb-4 login-tagline"><i>Experience Analytics</i> to make your existing forms perform better</h1>
            <div class="body-login">
              Understand why visitors leave your forms<br />or check-out and fix it.
            </div>
          </template>
        </div>
        <div class="auth-image"></div>
        <!-- /.Text -->
      </div>
      <template v-if="$route.path.indexOf('/signup') !== -1">
        <div class="auth-clients text-dark d-none d-xl-block col-xl-7 ">
          Companies worldwide, from startups to the biggest corporations use Exatom to optimise their forms.
          <div class="mt-3 text-center">
            <img class="mb-3" loading="lazy" decoding="async" width="75" src="https://exatom.io/wp-content/uploads/2024/03/logo-hema_black.svg" title="Hema" alt="Hema" />
            <img class="mb-3" loading="lazy" decoding="async" width="75" src="https://exatom.io/wp-content/uploads/2024/03/logo-veritas_black.svg" title="Veritas" alt="Veritas" />
            <img class="mb-3" loading="lazy" decoding="async" width="75" src="https://exatom.io/wp-content/uploads/2024/03/logo-deltafiber_black.svg" title="Delta" alt="Delta" />
            <img class="mb-3" loading="lazy" decoding="async" width="75" src="https://exatom.io/wp-content/uploads/2024/03/logo-vgz_black.svg" title="VGZ" alt="VGZ" />
            <img class="mb-3" loading="lazy" decoding="async" width="75" src="https://exatom.io/wp-content/uploads/2024/03/logo-bmw_black.svg" title="BMW" alt="BMW" />
            <img class="mb-3" loading="lazy" decoding="async" width="75" src="https://exatom.io/wp-content/uploads/2024/03/logo-brooks_black.svg" title="Brooks Running" alt="Brooks Running" />
            <img class="mb-3" loading="lazy" decoding="async" width="75" src="https://exatom.io/wp-content/uploads/2024/03/logo-inno_black.svg" title="Inno" alt="Inno" />
            <img class="mb-3" loading="lazy" decoding="async" width="75" src="https://exatom.io/wp-content/uploads/2024/03/logo-kia_black.svg" title="Kia" alt="Kia" />
            <img class="mb-3" loading="lazy" decoding="async" width="75" src="https://exatom.io/wp-content/uploads/2024/03/logo-toyota_black.svg" title="Toyota" alt="Toyota" />
          </div>
        </div>
      </template>
      <!-- / Side container -->

      <!-- Form container -->
      <div class="theme-bg-white d-flex col-xl-5 align-items-center p-5">
        <!-- Inner container -->
        <!-- Have to add `.d-flex` to control width via `.col-*` classes -->
        <div class="d-flex col-sm-7 col-md-5 col-lg-9 px-0 px-xl-login mx-auto">
          <div class="w-100">

            <div class="d-flex justify-content-center align-items-center">
              <template v-if="$route.path.indexOf('/signup') !== -1">
                <a href="https://exatom.io/?utm_source=exatom&utm_content=olympus-signup" target="_self">
                  <img class="logo mb-xl-3" :src="logoTagline" alt="Exatom logo" width="180">
                </a>
              </template>
              <template v-else>
                <img class="logo" :src="logo" alt="Exatom logo">
              </template>
            </div>

            <router-view />
          </div>
        </div>
        <div class="support small"
             v-if="$route.path.indexOf('/signup') !== -1">
          Questions? We have answers, just <a href="mailto:cs@exatom.io?subject=Signup%20question">email us!</a>
        </div>
      </div>
      <!-- / Form container -->

    </div>
  </div>
</template>

<!-- Page -->
<style src="@/vendor/styles/pages/authentication.scss" lang="scss"></style>

<script>
import logo from '@/assets/exatom-logo_blue.svg'
import logoTagline from '@/assets/exatom-logo_tagline.svg'

export default {
  name: 'Auth',
  data: () => ({
    logo,
    logoTagline
  })
}
</script>

<style scoped>

.auth-hero {
  padding-top: 15vh !important;
}

.auth-background {
  background-image: linear-gradient(to right bottom, #ffffff, #fcfdff, #f5fcff, #ecfcff, #e4fcff, #defafd, #d9f9fc, #d3f7fa, #cef3fc, #cbeffe, #cbeaff, #cce5ff);
}

.auth-image {
  background-image: url('~@/assets/man-point-right.svg');
  background-repeat: no-repeat;
  background-position: right 2rem bottom 40%;
  background-size: 20vh;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  transition-duration: 0.5s;
  transition-property: background-size;
}

.auth-clients {
  position: fixed;
  bottom: 2rem;
  text-align: center;
}

.auth-hero {
  padding: 8vw 5rem 0 4rem;
}

.support {
  position: absolute;
  bottom: 3rem;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0.75;
}
</style>
