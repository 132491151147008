// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import axios from 'axios'
import numeral from 'numeral'
import App from './App'
import router from './router'
import { isAllowed } from './utils'
import VueSocialSharing from 'vue-social-sharing'
import VCalendar from 'v-calendar'
import { Sortable, MultiDrag } from 'sortablejs'

import BootstrapVue from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'

import globals from './globals'
import Popper from 'popper.js'

import { VuePlausible } from 'vue-plausible'
Vue.use(VuePlausible, {
  domain: 'olympus.exatom.io',
  enableAutoPageviews: true,
  enableAutoOutboundTracking: false
})

import CodeDiff from 'v-code-diff'
Vue.use(CodeDiff)

import { library } from '@fortawesome/fontawesome-svg-core' // import the fontawesome core
import { faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons' // import specific icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome' // import font awesome icon component
library.add(faTwitter, faLinkedinIn)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true

Vue.use(BootstrapVue)
Vue.use(VueClipboard)
Vue.use(VueSocialSharing)
Vue.use(VCalendar)

/**
 * Working with dates
 */
import VueLuxon from 'vue-luxon'
import { DateTime, Duration, Settings } from 'luxon'

// Test I18n
// Settings.defaultLocale = 'en-US'
// Settings.defaultLocale = 'fr'

Vue.use(VueLuxon)
Vue.filter('dateShort', input => DateTime.fromISO(input).toLocaleString(DateTime.DATE_SHORT))
Vue.filter('date', input => DateTime.fromISO(input).toLocaleString(DateTime.DATE_MED))
Vue.filter('datetime', input => DateTime.fromISO(input).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS))
Vue.filter('dateRelative', function (input, fullFormat = DateTime.DATE_MED) {
  if (Math.abs(DateTime.now().ordinal - DateTime.fromISO(input).ordinal) <= 30) {
    return DateTime.fromISO(input).toRelative()
  }
  return DateTime.fromISO(input).toLocaleString(fullFormat)
})
Vue.filter('timeSeconds', seconds => {
  const duration = Duration.fromObject({ minutes: 0, seconds }).normalize().toObject()
  let result = []
  if (duration.hours) result.push(duration.hours + 'h')
  if (duration.minutes) result.push(duration.minutes + 'm')
  result.push(Math.round(duration.seconds) + 's')
  return result.join(' ')
})
Vue.filter('timezone', input => {
  const dt = DateTime.now().setZone(input)
  return input + ' (UTC ' + dt.zone.formatOffset(dt.offset, 'short') + ')'
})

// Global RTL flag
Vue.mixin({
  data: globals
})

let axiosRequestsPending = 0
const axiosLoadingIgnorePatterns = [
  process.env.VUE_APP_OLYMPUS_SCREENSHOT_API + '/screenshot'
]

axios.interceptors.request.use(
  config => {
    if ((typeof config.data === 'string' && config.data.indexOf('axios-hide') !== -1) || (typeof config.data === 'object' && typeof config.data['axios-hide'] !== 'undefined')) {
      // Do not show loading indicator
      return config
    }

    axiosRequestsPending++
    if (axiosLoadingIgnorePatterns.indexOf(config.url) === -1) {
      document.body.classList.add('axios-loading')
    }
    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => {
    if (--axiosRequestsPending <= 0) {
      document.body.classList.remove('axios-loading')
      axiosRequestsPending = 0
    }
    return response
  },
  error => {
    if (--axiosRequestsPending <= 0) {
      document.body.classList.remove('axios-loading')
      axiosRequestsPending = 0
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$api = {
  async login ({ email, password }) {
    await axios.get(`${process.env.VUE_APP_OLYMPUS_API}/auth/jwt/token`, {
      auth: { username: email, password: password },
      headers: { Accept: 'application/x.olympus-api.v1.1+json' }
    }).then(({ data: json }) => {
      localStorage.apiToken = json.data.jwt
    })
  },
  async refresh () {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Refreshing login token')
    }
    await axios.get(`${process.env.VUE_APP_OLYMPUS_API}/auth/jwt/refresh`, {
      headers: { Accept: 'application/x.olympus-api.v1.1+json', Authorization: `Bearer ${localStorage.apiToken}` }
    }).then(({ data: json }) => {
      localStorage.apiToken = json.data.jwt
    }).catch(e => this._catch(e))
  },
  async logout () {
    await axios.get(`${process.env.VUE_APP_OLYMPUS_API}/auth/jwt/logout`, {
      headers: { Accept: 'application/x.olympus-api.v1.1+json', Authorization: `Bearer ${localStorage.apiToken}` }
    }).catch().then(() => {
      delete localStorage.apiToken
      this.mainApp.client = null
      router.push({ path: '/login' })
    })
  },
  async resetInit (email) {
    await axios.post(`${process.env.VUE_APP_OLYMPUS_API}/auth/password/email`, { email }, {
      headers: { Accept: 'application/x.olympus-api.v1.1+json' }
    }).catch(e => this._catch(e))
  },
  async resetEnd (email, token, password, src = null) {
    return (await axios.post(`${process.env.VUE_APP_OLYMPUS_API}/auth/password/reset`, { email, token, password, password_confirmation: password, src }, {
      headers: { Accept: 'application/x.olympus-api.v1.1+json' }
    }).catch(e => this._catch(e))).data
  },
  async get (uri, clientHash) {
    return (await axios.get(`${process.env.VUE_APP_OLYMPUS_API}${uri}`, await this._config(clientHash)).catch(e => this._catch(e))).data
  },
  async post (uri, data, clientHash, axiosConfig = {}) {
    if (axiosConfig.responseType === 'blob') {
      return (await axios.post(`${process.env.VUE_APP_OLYMPUS_API}${uri}`, data, { ...await this._config(clientHash), ...axiosConfig }).catch(e => this._catch(e)))
    }
    return (await axios.post(`${process.env.VUE_APP_OLYMPUS_API}${uri}`, data, { ...await this._config(clientHash), ...axiosConfig }).catch(e => this._catch(e))).data
  },
  async patch (uri, data, clientHash) {
    return (await axios.patch(`${process.env.VUE_APP_OLYMPUS_API}${uri}`, data, await this._config(clientHash)).catch(e => this._catch(e))).data
  },
  async delete (uri, clientHash) {
    return (await axios.delete(`${process.env.VUE_APP_OLYMPUS_API}${uri}`, await this._config(clientHash)).catch(e => this._catch(e))).data
  },
  async assets (uri, data, config, catchError) {
    return (
      await axios.post(`${process.env.VUE_APP_OLYMPUS_ASSET_API}${uri}`, data, { ...await this._config(), ...config })
        .catch(e => {
          if (typeof catchError === 'boolean' && catchError === false) {
            return Promise.reject(e)
          }
          return this._catch(e)
        })
    )
  },
  async screenshot (uri, data, config, catchError) {
    return (
      await axios.post(`${process.env.VUE_APP_OLYMPUS_SCREENSHOT_API}${uri}`, data, { ...await this._config(), ...config })
        .catch(e => {
          if (typeof catchError === 'boolean' && catchError === false) {
            return Promise.reject(e)
          }
          return this._catch(e)
        })
    )
  },
  async event (uri, clientHash) {
    return (await axios.get(`${process.env.VUE_APP_OLYMPUS_EVENT_API}${uri}`, await this._config(clientHash))).data
  },
  async eventPost (uri, data, clientHash) {
    return (await axios.post(`${process.env.VUE_APP_OLYMPUS_EVENT_API}${uri}`, data, await this._config(clientHash))).data
  },
  async _config (clientHash) {
    const headers = {
      headers: {
        Accept: 'application/x.olympus-api.v1.1+json',
        Authorization: `Bearer ${await this._token()}`
      }
    }

    if (typeof clientHash !== 'undefined' && clientHash) {
      headers.headers['X-Client-Hash'] = clientHash
    }

    return headers
  },
  _vue: new Vue(),
  errors: [],
  getLastErrors (amount = 1) {
    return this.errors.slice(amount * -1)
  },
  getLastErrorsAsJSON (amount = 1) {
    return JSON.stringify(this.getLastErrors(amount))
  },
  _catch (error) {
    const nonVerboseMessages = [
      'password reset token is invalid',
      'user with this email address already exists',
      'Unable to authenticate with invalid token'
    ]

    for (let i = 0; i < nonVerboseMessages.length; i++) {
      if (error.response && error?.response?.data?.message && error?.response?.data?.message?.indexOf(nonVerboseMessages[i]) !== -1) {
        if (error.response.data.message.indexOf('Unable to authenticate with invalid token') !== -1) {
          delete localStorage.apiToken
          this.$router.push({ path: '/login' })
        }
        return Promise.reject(error)
      }
    }

    const h = this._vue.$createElement

    console.trace({ error })
    if (error?.response?.data?.message) {
      this.errors.push({
        url: error?.config?.url,
        statusCode: error.response?.status,
        message: error.response.data.message,
        timestamp: Date.now()
      })
    }

    this._vue.$bvToast.toast([
      ...(error.response && error?.response?.data?.message ? [ error.response.data.message ] : []),
      h('div', { attrs: { class: 'text-muted mt-1' } }, error.response ? `HTTP status code: ${error.response.status}` : 'Network error'),
      h('div', { attrs: { class: 'd-flex justify-content-between' } }, [
        h('div', { attrs: { class: 'text-muted small text-monospace' } }, (new Date()).toISOString()),
        h('div', {}, [
          h('a', {
            attrs: {
              class: 'cursor-pointer text-danger small',
            },
            on: {
              click: () => this.mainApp.createTicket('User-interface error', '', true)
            }
          }, 'report')
        ]),
      ]),
    ], {
      title: [
        h('div', { class: ['d-flex', 'flex-grow-1', 'align-items-baseline'] }, [
          h('strong', { class: 'mr-auto' }, [
            h('i', { class: 'material-icons mr-1', style: 'font-size: 16px; vertical-align: sub;' }, 'error'),
            'Hmm, we didn\'t expect this'
          ])
        ])
      ],
      noAutoHide: true,
      solid: true,
      variant: 'danger'
    })

    return Promise.reject(error)
  },
  _loading: null,
  async toLogin () {
    if (router?.currentRoute?.name !== 'login') {
      await router.push({
        path: '/login',
        query: { redirect: window.location.pathname + window.location.search }
      })
    }
    return new Promise(resolve => {})
  },
  async _token () {
    const token = this.getToken()
    if (!token) {
      return await this.toLogin()
    }

    const now = Date.now() / 1000
    const exp = this.getTokenExpiryTime()

    if (exp < now) {
      return await this.toLogin()
    }

    this.addTags()
    return localStorage.apiToken
  },
  getToken () {
    if (!localStorage.apiToken) return null
    try {
      if (JSON.parse(atob(localStorage.apiToken.split('.')[1])).exp) {
        return localStorage.apiToken
      }
    } catch (e) {
      delete localStorage.apiToken
    }
    return null
  },
  getTokenExpiryTime () {
    const token = this.getToken()
    let exp = 0
    try {
      exp = JSON.parse(atob(token.split('.')[1])).exp
    } catch (e) {}
    return exp
  },
  isLoginTokenExpired () {
    const now = Date.now() / 1000
    const exp = this.getTokenExpiryTime()
    return exp < now
  },
  async conditionallyRefreshToken () {
    const exp = this.getTokenExpiryTime()
    if (process.env.NODE_ENV !== 'production') {
      console.log('Login token remaining time ', { seconds: exp - Math.ceil(Date.now() / 1000) })
    }

    // Verify if we need to refresh the login token
    // -> if current token is not expired
    // -> only if it's going to expire in the next 15m
    if (this.isLoginTokenExpired()) return
    if ((exp - (Date.now() / 1000)) < 15 * 60) {
      this.refresh()
    }
  },
  tagsAdded: [],
  addTags (tags = []) {
    // Exatom
    if (!this.tagsAdded.includes('exatomEvent') && (!tags.length || tags.includes('exatomEvent'))) {
      try {
        const exatomEvent = document.createElement('script')
        const s = document.getElementsByTagName('script')[0]
        exatomEvent.src = 'https://assets.exatom.io/event.js?clientCode=D095'
        s.parentNode.insertBefore(exatomEvent, s)
      } catch (e) {}
      this.tagsAdded.push('exatomEvent')
    }

    // Exatom conversion tag
    if (!this.tagsAdded.includes('exatomConversion') && tags.includes('exatomConversion')) {
      try {
        const exatomConversion = document.createElement('script')
        const s = document.getElementsByTagName('script')[0]
        exatomConversion.src = 'https://assets.exatom.io/conversion.js?clientCode=D095&tagCode=M6WPL'
        s.parentNode.insertBefore(exatomConversion, s)
      } catch (e) {}
      this.tagsAdded.push('exatomConversion')
    }

    // Zoho support and knowledge base
    if (!this.tagsAdded.includes('zohoSupport') && (!tags.length || tags.includes('zohoSupport'))) {
      try {
        const zohoSupport = document.createElement('script')
        const s = document.getElementsByTagName('script')[0]
        zohoSupport.src = 'https://desk.zoho.eu/portal/api/feedbackwidget/29268000000559265?orgId=20072038995&displayType=popout'
        s.parentNode.insertBefore(zohoSupport, s)
      } catch (e) {}
      this.tagsAdded.push('zohoSupport')
    }
  }
}

router.onError(error => Vue.prototype.$api._catch(error))

Vue.filter('numeral', (input, format) => numeral(input).format(format))
Vue.filter('split', (input, delimiter, position = 0) => input.split(delimiter)[position])

Sortable.mount(new MultiDrag())
Vue.directive('sortable', {
    inserted: function (el, binding) {
        el.$sortable = new Sortable(el, binding.value || {})
    }
})

Vue.prototype.$api.mainApp = new Vue({
  data: {
    client: null,
    me: null,
    shiftPresses: 0,
    setupStatusStep: null,
    debugData: {} // Will be shown on ALT+click the profile icon and is reset every pageview
  },
  methods: {
    hasRole (roleName, useInitialRoles = false) {
      if (!this.me) return false

      const roles = useInitialRoles && this.me.initialRoles ? this.me.initialRoles : this.me.roles

      if (roleName.indexOf('*') !== -1) {
        // Partial matching
        return roles.filter(role => role.name.indexOf(roleName.replace(/\*/g, '')) !== -1).length > 0
      }

      // Exact match
      return roles.map(role => role.name).includes(roleName)
    },
    clearRoles () {
      if (!this.me || this.me.roles.filter(r => r.name === 'subscription.expired').length) return
      this.me.initialRoles = this.me.roles
      this.me.roles = [{ name: 'subscription.expired' }]
    },
    resetRoles () {
      if (typeof this.me.initialRoles !== 'undefined') {
        this.me.roles = this.me.initialRoles
      }
      this.setupStatusStep = null
    },
    getCompanyProperty (name) {
      if (!this.$root?.me?.organization?.properties) return null
      return this.$root.me.organization.properties.find(p => p.type.name === name) ?? null
    },
    getWebsiteProperty (name) {
      if (!this.client?.properties) return null
      return this.client.properties.find(p => p.type.name === name) ?? null
    },
    sessionReplayIsAvailable () {
      const prop = this.$root.getWebsiteProperty('Session replays')
      return !!(prop && isAllowed(prop.value))
    },
    reload () {
      this.$api.get('/me').then(json => {
        this.me = json.data
        this.me.initialRoles = this.me.roles

        // Restrict some environments to only admins
        if (
          ['olympus', 'olympus-prod', 'localhost', 'olympus-local'].indexOf(document.location.host.split(':')[0].split('.')[0]) === -1 &&
          !(this.hasRole('admin.internal') || this.hasRole('earlyAccess'))
        ) {
          if (document.location.host.split(':')[0].split('.')[0] === 'design') {
            const token = localStorage.apiToken
            delete localStorage.apiToken
            window.location = 'https://olympus.exatom.io/login?token=' + token
          } else {
            this.$api.logout().then(
              r => this.$router.push({ path: '/login?error=Unauthorized access to this environment.' })
            )
          }
        }
      }).catch(error => {
        delete localStorage.apiToken
        this.$router.push({ path: '/login?error=Error reloading your profile, logged out.' })
      })
    },
    createTicket (subject = null, body = null, includeClientAndError = false) {
      if (!this.me || !window.zsLoadAutoSuggestions) return

      window.zsLoadAutoSuggestions()

      if ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 850) {
        window.setTimeout(() => {
          window.closeFBSlideicon()
          window.setTimeout(() => window.slidmenuclose(), 500)
        }, 500)
      }

      window.setTimeout(() => {
        document.getElementById('feedbPopupSbmtBtn').className = 'btn btn-create'
        document.getElementById('feedbNameTxtField').value = this.me.name
        document.getElementById('feedbEmailTxtField').value = this.me.email
        if (subject && subject.length) {
          document.getElementById('feedbackSubject').value = subject
        }
        if (body && body.length) {
          document.getElementById('feedbackDescription').value = body
        }
        if (includeClientAndError) {
          document.getElementById('feedbackDescription').value += '\n\n------------------------\n\nClient: ' + this.$root.client.name + '\nClient code: ' + this.$root.client.client_code + '\n\nErrors:\n' + this.$api.getLastErrorsAsJSON()
        }
        document.getElementById('searchBox').focus()
      }, 100)
    },
    async isSubscriptionExpired (clientHash = null) {
      if (clientHash) {
        const status = (await this.$api.get('/setup/status', clientHash)).step
        return status === 1000
      } else if (this.client) {
        const prevStatus = this.setupStatusStep
        this.setupStatusStep = (await this.$api.get('/setup/status', this.client.client_hash)).step
        if (this.setupStatusStep === 1000) {
          if (this.$router.currentRoute.name !== 'account') {
            this.$router.push({ name: 'account' })
          }

          // Limit UI functions to the finance role
          if (!this.$root.hasRole('admin.internal') && !this.$root.client.subscription_usage.subscription_plan) {
            this.clearRoles()
          }
        } else if (prevStatus === 1000) {
          // Re-enable
          this.resetRoles()
          if (this.$router.currentRoute.name !== 'dashboard') {
            this.$router.push({ name: 'dashboard' })
          }
        }
      }
    },
    cl (data) {
      console.log(data)
    },
    async toggleStatus (model, id, currentStatus, clientHash = null) {
      const uri = (model.indexOf('/', 1) === -1) ? '/' + model + '/' + id : model
      const result = await this.$api.patch(
        uri,
        { status: (['ACTIVE', 'SCHEDULED'].includes(currentStatus)) ? 'INACTIVE' : (currentStatus === 'INACTIVE' ? 'ACTIVE' : currentStatus) },
        clientHash ?? this.client.client_hash
      )
      return result?.data?.status
    },
    getDomains () {
      return this.client?.domains ?? []
    },
    isValidDomain (uri) {
      if (this.getDomains().length === 0) {
        return true
      }

      if (uri.trim().match(/^https?:\/\//gi) || uri.indexOf('/') !== -1) {
        // A full uri was supplied, get domain
        uri = this.extractHostname(uri)
      }

      if (this.getDomains().includes(uri)) {
        // Exact match
        return true
      } else {
        // Partial matching, allow subdomains too
        for (const domain of this.getDomains()) {
          if (uri.trim().toLowerCase().indexOf(domain.trim().toLowerCase()) !== -1) {
            return true
          }
        }
      }
      return false
    },
    extractHostname (url) {
      let hostname
      if (url.indexOf('//') > -1) {
        hostname = url.split('/')[2]
      } else {
        hostname = url.split('/')[0]
      }

      // find & remove port number
      hostname = hostname.split(':')[0]

      // Edge cases
      // if there was a "?" but no slashes
      hostname = hostname.split('?')[0]
      // if there was a "&" but no slashes -> malformed url
      hostname = hostname.split('&')[0]
      // if there was a "#"
      hostname = hostname.split('#')[0]

      return hostname
    },
    clientCurrencyFormat (number, maximumFractionDigits = 0) {
      const options = {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 0
      }
      try {
        return new Intl.NumberFormat(
          (new Intl.NumberFormat()).resolvedOptions().locale,
          {
            ...options,
            currency: this.client.country.currency_code_iso
          }).format(number)
      } catch (e) {
        return new Intl.NumberFormat(
          'nl-BE',
          {
            ...options,
            currency: 'EUR'
          }).format(number)
      }
    },
    addDebugDataToMenu (key, value) {
      this.debugData[key] = value
    },
    clearDebugDataFromMenu () {
      this.debugData = {}
    },
    getClient (clientHash) {
      const client = this.me.grants.combined.filter(grant => grant.client_hash === clientHash)
      return (client.length === 1) ? client[0] : null
    }
  },
  computed: {
    shouldShowSubscriptionOptions () {
      return isAllowed(this.getCompanyProperty('Subscription management')?.value)
    }
  },
  mounted () {
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Shift') {
        if (++this.shiftPresses >= 2) {
          this.$emit('shift-pressed-twice')
        } else {
          setTimeout(() => { this.shiftPresses = 0 }, 250)
        }
      }
    })

    // Check if subscription is expired
    window.setInterval(this.isSubscriptionExpired, 3600 * 1000)

    // Allow the $api prototype access the app
    this.$api.mainApp = this
  },
  watch: {
    client: {
      async handler () {
        if (this.client) {
          // $root.client is a subset of $root.me.grants, that do not contain all client details (not needed)
          // However if the client is loaded/switched, dynamically inject its props
          // this.client.properties = (await this.$api.get(`/clients/${this.client.client_hash}/properties`)).data
          this.$set(this.client, 'properties', (await this.$api.get(`/clients/${this.client.client_hash}/properties`)).data)
        }
      },
      immediate: true
    }
  },
  router,
  render: h => h(App)
}).$mount('#app')
